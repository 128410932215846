import React, { useState } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';
import api from '../utils/api';
import Cookies from 'js-cookie';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./common.css";
import "./MainPage.css";

import SportsLearning from './SportsLearning';  // SportsLearning 컴포넌트를 가져옵니다.

const eventImages = [
  {
    src: `${process.env.PUBLIC_URL}/images/slides/slide_sample1.png`,
    title: '다양한 이벤트 1',
    description: '이벤트 응모하고 상품받자 1!',
    buttonText: '바로가기'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/slides/slide_sample2.png`,
    title: '다양한 이벤트 2',
    description: '이벤트 응모하고 상품받자 2!',
    buttonText: '바로가기'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/slides/slide_sample1.png`,
    title: '다양한 이벤트 3',
    description: '이벤트 응모하고 상품받자 3!',
    buttonText: '바로가기'
  }
];

const sports = [
  {
    src: `${process.env.PUBLIC_URL}/images/freediving.svg`,
    title: '프리다이빙',
    description: '프리다이빙은 장비 없이 자유롭게 물속을 탐험하는 스포츠입니다. 호흡 훈련과 함께 깊은 바다에서의 특별한 경험을 제공합니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/scuba-diving.svg`,
    title: '스쿠버',
    description: '스쿠버는 수중 호흡 장비를 활용하여 바다 속 세상을 탐험하는 스포츠입니다. 다양한 해양 생물과 아름다운 수중 경관을 만날 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/mermaid.svg`,
    title: '머메이드',
    description: '머메이드는 인어의 움직임을 모방한 수중 스포츠입니다. 특별한 핀을 착용하고 우아한 수중 동작을 배우며 독특한 경험을 제공합니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/Pilates.svg`,
    title: '필라테스',
    description: '필라테스는 몸의 코어 근력을 강화하고 유연성을 향상시키는 운동입니다. 정확한 동작과 호흡을 통해 균형 잡힌 신체를 만들 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/ice-hockey.svg`,
    title: '아이스하키',
    description: '아이스하키는 빙판 위에서 펼쳐지는 역동적인 팀 스포츠입니다. 빠른 스케이팅과 정확한 퍽 컨트롤을 통해 짜릿한 경기를 즐길 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/snowboard.svg`,
    title: '스노우보드',
    description: '스노우보드는 눈 덮인 슬로프를 자유롭게 질주하는 겨울 스포츠입니다. 다양한 기술과 점프를 통해 스릴 넘치는 경험을 제공합니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/running.svg`,
    title: '러닝',
    description: '러닝은 가장 기본적이면서도 효과적인 유산소 운동입니다. 실외에서 자유롭게 달리며 체력을 향상시키고 스트레스를 해소할 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/camping.svg`,
    title: '캠핑',
    description: '캠핑은 자연 속에서 휴식과 모험을 동시에 즐길 수 있는 아웃도어 활동입니다. 가족, 친구들과 함께 특별한 추억을 만들 수 있습니다.'
  }
];

const WATER_SPORTS = ['프리다이빙', '스쿠버다이빙', '머메이드'];

Modal.setAppElement('#root');


function MainPage({ isLoginModalOpen, closeLoginModal, userId, setUserId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSport, setSelectedSport] = useState(null);
  const [password, setPassword] = useState(''); // 비밀번호 state 추가
  const navigate = useNavigate(); // useNavigate 훅 사용

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };

  const openModal = (sport) => {
    setSelectedSport(sport);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSport(null);
  };

  const handleSignupClick = (e) => {
    e.preventDefault();
    closeLoginModal();
    navigate('/signup');
  };

  const handleApplyClick = () => {
    navigate('/sports-learning');
  };
  
  return (
    <div className="main-container">
      <div className="event-section">
        <Slider {...settings}>
          {eventImages.map((image, index) => (
            <div key={index} className="slide">
              <div className="slide-block">
                <img src={image.src} alt={image.title} className="event-image" />
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="sports-section">
        <div className="sports-grid">
          {sports.map((sport, index) => (
            <div key={index} className="sport-item" onClick={() => openModal(sport)}>
              <img src={sport.src} alt={sport.title} />
              <span>{sport.title}</span>
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
      >
        {selectedSport && (
          <div className="mainpage-modal-content">
            <button onClick={closeModal} className="modal-close-btn">
              <i className="fas fa-times"></i>
            </button>
            <div className="modal-header">
              <img src={selectedSport.src} alt={selectedSport.title} className="modal-image" />
              <h2>{selectedSport.title}</h2>
            </div>
            <div className="modal-description">
              <p>{selectedSport.description}</p>
            </div>
            <div className="modal-footer">
              <button onClick={handleApplyClick} className="modal-button apply-btn">
                <i className="fas fa-plus-circle"></i> 신청
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={isLoginModalOpen}
        onRequestClose={closeLoginModal}
        className="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2>Login</h2>
            <button className="close-button" onClick={closeLoginModal}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder="Enter your ID"
              className="modal-input"
            />
            <input
              type="password"
              value={password} // 밀번호 입력 필드에 state 연결
              onChange={(e) => setPassword(e.target.value)} // 밀번호 변경 핸들러 추가
              placeholder="Enter your Password"
              className="modal-input"
            />
            <Link to="/signup" onClick={handleSignupClick} className="signup-link">회원가입</Link>
          </div>
        </div>
      </Modal>

    </div>
  );
}

export default MainPage;
