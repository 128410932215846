import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';

function Navigation({ onLoginClick, userId, level }) {
  return (
    <nav className="navigation">
      <div className="nav-container">
        <Link to="/" className="nav-logo">
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="DEEPS" />
        </Link>
        
        <Link to="/sports-learning" className="nav-link-item">
          <img src={`${process.env.PUBLIC_URL}/images/class.svg`} alt="클래스" className="nav-icon" />
          <span>클래스</span>
        </Link>
        
        <Link to="/travel_product" className="nav-link-item">
          <img src={`${process.env.PUBLIC_URL}/images/travel_ico.svg`} alt="여행" className="nav-icon" />
          <span>여행</span>
        </Link>
        
        <Link to="/shopping" className="nav-link-item">
          <img src={`${process.env.PUBLIC_URL}/images/shopping_ico.svg`} alt="쇼핑" className="nav-icon" />
          <span>쇼핑</span>
        </Link>

        {userId ? (
          <Link to="/user-profile" className="nav-user">
            <div className="user-icon-level">
              <span className="user-level">LV. {level}</span>
            </div>
            <span className="user-name">{userId}</span>
          </Link>
        ) : (
          <button className="nav-login" onClick={onLoginClick}>
            <span>로그인</span>
          </button>
        )}
      </div>
    </nav>
  );
}

export default Navigation;
